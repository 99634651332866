import { t } from '../../services/utils/translation';
import {
  CLEAR_TASK_DIF_VALUES,
  CLEAR_TASK_INITIAL_VALUES,
  CLEAR_TASK_VALUES,
  SET_ACCESS_POLICY_TASK,
  SET_DEFAULT_STATUS,
  SET_INITIAL_NEW_TASK,
  SET_INITIAL_TASK_FIELD_VALUES,
  SET_IS_ACTIVE_COUNTERPARTY_FILTER,
  SET_MARKED_TASK,
  SET_TASK_CURRENT_VALUES,
  SET_TASK_DIF_VALUES,
  TASKS_CLEAR_FILTERS,
  TASKS_SAVE_FILTERS,
} from './actionTypes';
import { IFormValuesTask } from '../../components/tasks/TaskForm/TaskForm';
import { initialTasksFilters } from './initialTasksFilters';
import { ITaskFilters } from '../../services/api/tasks/tasks/IGetPaginatedTasksParams';
import { ERROR_ACTION } from '../general/actionTypes';
import { ITaskClientAccessPolicy } from './initialTask';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import { TChangesValuesForm } from 'hooks/useGettDifferentValues/useGettDifferentValues';

//FILTERS
 
export const saveTasksFilters = (filters: ITaskFilters) => ({
  type: TASKS_SAVE_FILTERS,
  filters,
});

export const clearTasksFilters = () => ({
  type: TASKS_CLEAR_FILTERS,
  payload: initialTasksFilters,
});

export const setIsActiveCounterpartyFilter = (
  isActiveCounterpartyFilter: boolean
) => ({
  type: SET_IS_ACTIVE_COUNTERPARTY_FILTER,
  payload: isActiveCounterpartyFilter,
});

export const setMarkedTask = (id: number) => {
  const errorMessage = t(
    'Что-то пошло не так. Пожалуйста, свяжитесь с администратором. '
  );
  try {
    return {
      type: SET_MARKED_TASK,
      payload: id,
    };
  } catch (e) {
    return {
      type: ERROR_ACTION,
      payload: { errorMessage, e },
    };
  }
};

//FORM VALUES

export const setDefaultStatus = (
  payload: IClientSelectOptionV2<number> | undefined
) => ({
  type: SET_DEFAULT_STATUS,
  payload,
});

// OTHER

export const setTaskAccessPolicy = (payload: ITaskClientAccessPolicy) => ({
  type: SET_ACCESS_POLICY_TASK,
  payload,
});

//FORM VALUES

export const saveTaskCurrentValues =
  (pk: string) => (values: IFormValuesTask) => ({
    type: SET_TASK_CURRENT_VALUES,
    payload: { pk, values },
  });

export const setInitialFormTask = (pk: string) => (values: IFormValuesTask) => {
  return {
    type: SET_INITIAL_TASK_FIELD_VALUES,
    payload: { pk, values },
  };
};

export const clearTaskFormInitialValues = (pk: string) => ({
  type: CLEAR_TASK_INITIAL_VALUES,
  payload: pk,
});

export const clearTaskFormValues = (pk: string) => ({
  type: CLEAR_TASK_VALUES,
  payload: pk,
});

export const setTaskFormDifValues =
  (pk: string) => (difValues: TChangesValuesForm[]) => ({
    type: SET_TASK_DIF_VALUES,
    payload: { pk, difValues },
  });

export const clearTaskFormDifValues = (pk: string) => ({
  type: CLEAR_TASK_DIF_VALUES,
  payload: pk,
});

export const setInitialNewTask = () => ({
  type: SET_INITIAL_NEW_TASK,
});
