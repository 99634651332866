import React, { FC, useState, memo } from 'react';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import styles from './index.module.scss';

type PropsType = {
  url: string;
  alt: string;
  className: string;
};

const ImgPreview: FC<PropsType> = (props) => {
  const { url, alt, className } = props;
  const [isLoaded, setIsLoaded] = useState(false);

  const openImg = (url: string) => {
    window.open(url, '_blank');
  };
  return (
    <div className={styles.img}>
      {!isLoaded && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
          }}
        >
          <LoaderFetch />
        </div>
      )}
      <img
        src={url}
        alt={alt}
        loading="lazy"
        onLoad={() => setIsLoaded(true)}
        onClick={() => url && openImg(url)}
        className={className}
        style={{ opacity: isLoaded ? 1 : 0 }}
      />
    </div>
  );
};

export default memo(ImgPreview);
