import React, { FC } from 'react';
import { Container, Row } from 'reactstrap';
import styles from './index.module.scss';

import classNames from 'clsx';
import { IFileBucket } from '../../../services/api/documents/file-bucket/IGetResponseFileBuckets';
import { parseISO } from 'date-fns';
import DocPreview from './DocPreview';
import useRead from '../../../hooks/useRead';
import { readEmployeeApiV2 } from '../../../services/api/staff/employee/employeeApi';
import { IEmployeeWrapper } from '../../../services/api/staff/employee/IEmployee';
import {
  formatDateTime,
  sliceTimestamp,
} from '../../../services/utils/dateHelper/dateHelper';
import { getFileLink } from 'services/api/documents/file-bucket/fileBucketApi';
import ImgPreview from './ImgPreview';
import useGetLinkForFile from './hooks/useGetLinkForFile';
import getFileExtension from './utils/getFileExtension';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import DownloadButton from './ui/DownloadButton';
import useModernDownload from './hooks/useModernDownload';
 

type PropsType = {
  fileBucket: IFileBucket;
  fileName: string;
};

const FilePreview: FC<PropsType> = (props) => {
  const { fileBucket, fileName } = props;
  const pk = fileBucket?.file_bucket_author_id;
  const { data: employeeFullName, isLoading: isLoadingEmployee } = useRead({
    getDataApi: readEmployeeApiV2,
    params: { pk },
    convertData: (payload: IEmployeeWrapper): string => {
      return payload?.employee.employee_full_name || '';
    },
    condition: !!pk,
    initialData: '',
  });
  const fileType = getFileExtension(fileName);
  const fullUrl = getFileLink(fileName);

  const {
    blob,
    urlBlob,
    isLoading: isLoadingUrlFile,
  } = useGetLinkForFile(fileName);

  const handleDownload = useModernDownload(
    blob as Blob,
    sliceTimestamp(fileName)
  );
  const isLoading = isLoadingEmployee || isLoadingUrlFile;
  return (
    <>
      {isLoading && <LoaderFetch />}
      {fullUrl && (
        <Container>
          <Row className="justify-content-between pb-3">
            <div
              className={classNames(
                'pl-0',
                styles['document-preview-subheader']
              )}
            >
              {employeeFullName}
            </div>
            <div className={classNames('float-right')}>
              <DownloadButton
                onClick={handleDownload}
                fileName={sliceTimestamp(fileName)}
              />
            </div>
          </Row>

          <Row className="justify-content-md-center">
            {urlBlob ? (
              <ImgPreview url={urlBlob} alt={fileName} className={styles.img} />
            ) : (
              <DocPreview src={blob} fileType={fileType} />
            )}
          </Row>
          <Row className={classNames(styles.subtitle, 'mt-3')}>
            {fileBucket?.time_added &&
              formatDateTime(parseISO(fileBucket.time_added))}
          </Row>
        </Container>
      )}
    </>
  );
};

export default FilePreview;
