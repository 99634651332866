import { isImg } from 'pages/documents/utils/isImg/isImg';
import { useCallback, useEffect, useState } from 'react';
import {
  createBlobFromFileApiV2,
  createLinkFromFileApiV2,
} from 'services/api/documents/file-bucket/fileBucketApi';
import { getMimeType } from 'services/utils/getMimeType/getMimeType';
 
const useGetLinkForFile = (fileName: string) => {
  const [blob, setBlob] = useState<null | Blob>(null);
  const [urlBlob, setUrlBlob] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getBlob = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    try {
      const urlFromBlob = await createBlobFromFileApiV2(fileName);
      const mimeType = getMimeType(fileName);
      const correctedBlob = new Blob([urlFromBlob], { type: mimeType });

      setBlob(correctedBlob);
    } catch (err) {
      throw new Error(err as string);
    } finally {
      setIsLoading(false);
    }
  }, [fileName]);

  const getUrlBlob = useCallback(async (): Promise<void> => {
    try {
      const urlFromBlob = await createLinkFromFileApiV2(fileName);

      setUrlBlob(urlFromBlob);
    } catch (err) {
      throw new Error(err as string);
    } finally {
      setIsLoading(false);
    }
  }, [fileName]);

  useEffect(() => {
    void getBlob();
    if (isImg(fileName)) {
      getUrlBlob();
    }
  }, [fileName, getBlob, getUrlBlob]);

  return {
    blob,
    urlBlob,
    isLoading,
  };
};

export default useGetLinkForFile;
