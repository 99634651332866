import { useDispatch, useSelector } from 'react-redux';
import { IIdentityListOfApplicableInstallationsEntity } from '../../../services/api/auth/IRegisterIdentity';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import classNames from 'clsx';
import styles from '../Breadcrumbs/index.module.scss';
import React from 'react';
import {
  setAccessToken,
  setActiveInstallation,
} from '../../../store/auth/actions';
import { getIdentityListOfApplicableInstallationsFromStore } from '../../../store/auth/selectors';
import { getActiveInstallationClientName } from 'store/auth/selectors';
import { changeInstallation } from 'store/reducers/action';
import { getInstallationPkFromStore } from 'store/general/selectors';
import CookieManager from 'models/CoockieManager/CookieManager';
import InstallationCookie from 'models/CoockieManager/InstallationCookie/InstallationCookie';

export const InstallationsDropDown = () => {
  const dispatch = useDispatch();

  const currentInstallationPk = useSelector(getInstallationPkFromStore);

  const handleChangeInstallation = (
    domain: string,
    installationClientName: string,
    installationPk: number
  ) => {
    if (currentInstallationPk !== installationPk) {
      const cookieManager = new CookieManager();
      const installationCookie = new InstallationCookie(cookieManager);
      installationCookie.setActiveInstallation(
        domain,
        installationClientName,
        installationPk
      );

      dispatch(
        setActiveInstallation(domain, installationClientName, installationPk)
      );
      dispatch(setAccessToken(installationPk));
    }
  };

  const installations = useSelector(
    getIdentityListOfApplicableInstallationsFromStore
  );

  const activeInstallationName = useSelector(getActiveInstallationClientName);
  const extractHandleClickDropdownItem =
    (
      installationCompanyName: string,
      installationClientName: string,
      installationPk: number
    ) =>
    () => {
      if (currentInstallationPk !== installationPk) {
        handleChangeInstallation(
          installationClientName,
          installationCompanyName,
          installationPk
        );
        dispatch(changeInstallation());
      }
    };

  return (
    <>
      {activeInstallationName && (
        <UncontrolledDropdown
          className={classNames(
            'd-flex flex-row justify-content-start align-items-center '
          )}
        >
          <DropdownToggle
            className={classNames(styles.toggle, 'd-flex flex-row')}
          >
            {activeInstallationName}
          </DropdownToggle>
          <DropdownMenu left="true" >
            {installations &&
              installations.map(
                (company: IIdentityListOfApplicableInstallationsEntity) => {
                  const {
                    installation_company_name,
                    installation_name,
                    installation_pk,
                  } = company;

                  const handleClickDropdownItem =
                    extractHandleClickDropdownItem(
                      installation_company_name,
                      installation_name,
                      installation_pk
                    );
                  return (
                    <DropdownItem
                      onClick={handleClickDropdownItem}
                      key={installation_pk}
                    >
                      <option>{installation_company_name}</option>
                    </DropdownItem>
                  );
                }
              )}
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </>
  );
};
