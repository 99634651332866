import { useCallback } from 'react';
import { createBlobFromFileApiV2 } from 'services/api/documents/file-bucket/fileBucketApi';
import { sliceTimestamp } from 'services/utils/dateHelper/dateHelper';
import { getMimeType } from 'services/utils/getMimeType/getMimeType';

const DELAY_MS = 400;

const useDownloadAllFiles = () => {
  return useCallback(async (fileNames: string[]) => {
    for (let i = 0; i < fileNames.length; i++) {
      const fileName = fileNames[i];
      try {
        // Получаем данные файла в виде Blob
        const blobData = await createBlobFromFileApiV2(fileName);
        const mimeType = getMimeType(fileName);
        const fileBlob = new Blob([blobData], { type: mimeType });

        // Создаем временную ссылку для скачивания
        const url = URL.createObjectURL(fileBlob);
        const a = document.createElement('a');
        a.href = url;
        const truncatedName = sliceTimestamp(fileName);
        a.download = truncatedName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error(`Ошибка при скачивании файла ${fileName}:`, error);
      }
      // Задержка между скачиваниями
      await new Promise((resolve) => setTimeout(resolve, DELAY_MS));
    }
  }, []);
};

export default useDownloadAllFiles;
