import React, { FC, PropsWithChildren } from 'react';
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import { ReactComponent as MoreIcon } from 'img/icons/icon-more.svg';
import { Direction } from 'reactstrap/es/Dropdown';

import styles from './index.module.scss';
import ActionsDropdownItem from './ActionsDropdownItem';
import MassActionsDropdown from './MassActionsDropdown';

export interface ActionsDropdownProps {
  classNameBtn?: string;
  direction?: Direction;
  className?: string;
}

const ActionsDropdown: FC<PropsWithChildren<ActionsDropdownProps>> = ({
  children,
  classNameBtn,
  direction = 'left',
  className,
}) => {
  return (
    <UncontrolledButtonDropdown direction={direction} className={className}>
      <DropdownToggle className={`${classNameBtn} ${styles.toggle}`}>
        <MoreIcon />
      </DropdownToggle>
      <DropdownMenu className={`${styles.menu}`} right>
        {children}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

export { ActionsDropdown, ActionsDropdownItem, MassActionsDropdown };
