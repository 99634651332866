import IFileFormValues from 'components/documents/FileBucketForm/IFileFormValues';
import { IKeyValueString } from 'services/interfaces/IKeyValue';
import { t } from 'services/utils/translation';
import * as Yup from 'yup';
import { IDocumentFormValues } from 'components/documents/FileBucketForm/IDocumentFormValues';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';

class FileBucket implements IDocumentFormValues {
  public pk?: number;
  public file_bucket_author_id: number;
  public file_bucket_name: string = '';
  public file_bucket_schema?: IClientSelectOptionV2;
  public file_bucket_schema_pk?: number;
  public file_bucket_populated_schema: IKeyValueString = {};
  public list_of_uploaded_files: File[] = [];
  public file_bucket_description: string = '';
  public parent_ui_element: string = '';
  public list_of_uploaded_file_names: string[] = [];
  public string_of_comma_separted_file_names_to_delete?: string = '';
  public uploadedFiles: IFileFormValues[] = [];
  public time_added?: string;
  public sent_to_archive?: boolean = false;
  public sent_to_archive_at?: string; //
  public sent_to_archive_author_dk?: string;
  public received_to_archive?: boolean = false;
  public received_to_archive_at?: string;
  public received_to_archive_author_dk?: string;
  constructor(authorId: number, parent_ui_element: any, pk?: number) {
    this.file_bucket_schema_pk = this.file_bucket_schema?.value
      ? Number(this.file_bucket_schema?.value)
      : undefined;
    this.file_bucket_author_id = authorId;
    this.pk = pk;
    this.parent_ui_element = parent_ui_element;
  }

  public static validationSchema = (): Yup.ObjectSchema<any> => {
    return Yup.object({
      file_bucket_name: Yup.string()
        .required(t('Укажите название документа'))
        .min(1, t('Текст не может быть короче 1 символа.')),
      file_bucket_schema: Yup.object().required(t('Укажите тип документа')),

      list_of_uploaded_files: Yup.array()
        .min(1, t('Необходимо добавить хотя бы один файл'))
        .dataSizeIsValid()
        .fileNameLengthValid(),
    });
  };
}

export default FileBucket;
