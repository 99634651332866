import React, { FC, useCallback, useMemo } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Button, Form, FormGroup } from 'reactstrap';
import { useSelector } from 'react-redux';
import { t } from 'services/utils/translation';
import classNames from 'clsx';

// Компоненты
import TextField from 'components/controls/TextField';
import DropZoneField from 'components/controls/DropZoneFiled/DropZoneField';
import HiddenField from 'components/controls/HiddenField';
import SelectFieldAdvanced from 'components/controls/SelectFieldColors';
import Checkbox from 'components/controls/Checkbox';
import LoaderFetch from '../../layouts/LoaderFetch/LoaderFetch';

// Хуки и утилиты
import { useGetInitialFileBucket } from './hooks/useGetInitialFileBucket';
import { usePostOld } from 'hooks/usePostOld/usePostOld';
import useDocumentToArchive from 'pages/documents/DocumentsPage/hooks/useDocumentToArchive';
import { useGetListOld } from 'hooks/useGetListOld';

// Сервисы и модели
import FileBucket from 'models/FileBucket';
import { getCurrentEmployeeFromStore } from '../../../store/general/selectors';
import { getFileSize } from 'services/utils/getFileSize/getFileSize';
import config from 'config.json';
import getErrorMessage from 'services/utils/getErrorMessage/getErrorMessage';
import getSuccessMessage from 'services/utils/getSuccessMessage/getSuccessMessage';

// API
import { createFileBucketApi } from 'services/api/documents/file-bucket/fileBucketApi';
import { getListFileBucketSchemaApi } from '../../../services/api/documents/file-bucket-schema/fileBucketSchemaApi';

// Типы и утилиты
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import { IFileBucketSchemaParams } from 'services/api/documents/file-bucket-schema/IGetFileBucketSchema';
import convertListSchemes from './utils/convertListSchema';
import fileBucketValuesToFormData from './utils/fileBucketValuesToFormData';

// Компоненты
import FileBucketFileList from './FileBucketFileList/FileBucketFileList';
import styles from './index.module.scss';

const { errorMessageCreate } = getErrorMessage(t('документ'));
const { successMessageCreate } = getSuccessMessage(t('Документ'));

type PropsType = {
  toggleCloseCreateModal: () => void;
  pk?: number;
  parent_ui_element: any;
};

const FileBucketForm: FC<PropsType> = (props) => {
  const { toggleCloseCreateModal, pk, parent_ui_element } = props;

  const isNew: boolean = !pk;
  const isViewMode = !isNew;
  const authorId = useSelector(getCurrentEmployeeFromStore).employee_pk;

  const initialParams = useMemo<IFileBucketSchemaParams>(
    () => ({
      pk: null,
      skip: 0,
      length: 100,
    }),
    []
  );

  const { data: listSchema, isLoading: isLoadingSchema } = useGetListOld({
    getDataApi: getListFileBucketSchemaApi,
    initialParams,
    convertData: convertListSchemes,
  });

  const { initialFileBucket, isLoadingFileBucket } = useGetInitialFileBucket({
    pk,
    listSchemes: listSchema,
    initial: new FileBucket(authorId, parent_ui_element, pk),
  });

  const createFileBucket = usePostOld({
    fetchApi: createFileBucketApi,
    errorMessage: errorMessageCreate,
    successMessage: successMessageCreate,
    successHandler: toggleCloseCreateModal,
  });

  const doArchivedDocument = useDocumentToArchive(toggleCloseCreateModal);

  const schemeSelection = (
    value: IClientSelectOptionV2,
    setFieldValue: Function
  ) => {
    setFieldValue('file_bucket_schema', value, false);
    setFieldValue('file_bucket_schema_pk', Number(value?.value), false);
  };

  const isViewDoReceivedDocumentButton = ({
    pk,
    isSentToArchive,
    isReceivedToArchive,
  }: {
    pk?: number;
    isSentToArchive?: boolean;
    isReceivedToArchive?: boolean;
  }): boolean => !!pk && !!isSentToArchive && !isReceivedToArchive;

  const handleSubmit = useCallback(
    async (
      values: any,
      { setSubmitting, setErrors, resetForm }: FormikHelpers<any>
    ) => {
      const formData = fileBucketValuesToFormData(values);
      setSubmitting(true);
      await createFileBucket(formData, setErrors, resetForm);
      setSubmitting(false);
    },
    []
  );

  const isLoading = isLoadingFileBucket || isLoadingSchema;

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialFileBucket}
      validationSchema={FileBucket.validationSchema()}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        isSubmitting,
      }) => {
        const size = getFileSize(values.list_of_uploaded_files);

        return (
          <Form onSubmit={handleSubmit} noValidate={true}>
            {isLoading ? (
              <LoaderFetch />
            ) : (
              <>
                <TextField
                  id="file_bucket_name"
                  label={t('Название')}
                  placeholder="Введите название.."
                  hasError={
                    errors.file_bucket_name != null &&
                    touched.file_bucket_name != null
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required={true}
                  value={values.file_bucket_name}
                  disabled={isViewMode}
                />
                <SelectFieldAdvanced
                  id="file_bucket_schema"
                  label={t('Тип документа')}
                  placeholder={t('Выберите тип документа')}
                  hasError={
                    errors.file_bucket_schema != null &&
                    touched.file_bucket_schema != null
                  }
                  onBlur={handleBlur}
                  onChange={(value: IClientSelectOptionV2) => {
                    schemeSelection(value, setFieldValue);
                  }}
                  required={true}
                  value={values.file_bucket_schema}
                  options={listSchema}
                  showDefaultOption={true}
                  disabled={isViewMode}
                />

                {!values.pk && (
                  <HiddenField
                    id="file_bucket_author_id"
                    value={authorId}
                    hasError={
                      errors.file_bucket_author_id != null &&
                      touched.file_bucket_author_id != null
                    }
                  />
                )}
                {values.pk && (
                  <HiddenField
                    id="id"
                    value={values.pk}
                    hasError={errors.pk != null && touched.pk != null}
                  />
                )}
                {isNew && (
                  <FormGroup
                    className={classNames(styles['container-dropzone'])}
                  >
                    <DropZoneField
                      name="list_of_uploaded_files"
                      onDrop={(acceptedFiles: File[]) => {
                        if (acceptedFiles.length === 0) {
                          return;
                        }
                        setFieldValue(
                          'list_of_uploaded_files',
                          values.list_of_uploaded_files.concat(acceptedFiles)
                        );
                      }}
                      hasError={
                        errors.list_of_uploaded_files != null &&
                        touched.list_of_uploaded_files != null
                      }
                      disabled={isViewMode}
                    />
                  </FormGroup>
                )}
                {values.list_of_uploaded_files.length > 0 && (
                  <div className="p-2">
                    <span
                      style={{
                        color: size > 20_000 ? 'red' : 'green',
                      }}
                    >
                      {size}kb
                    </span>
                    <span> {t('из')}</span>
                    <span> {config.maxDataSizeKb}kb</span>
                  </div>
                )}
                <FileBucketFileList />
                <div className="d-flex justify-content-between align-items-center">
                  {isNew && !initialFileBucket?.received_to_archive && (
                    <Checkbox
                      name="sent_to_archive"
                      label={t('Отправить в архив')}
                      defaultChecked={values.sent_to_archive}
                      hasError={errors.sent_to_archive != null}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('sent_to_archive', !e.target.checked);
                      }}
                      className={styles.checkBoxSentToArchive}
                      disabled={!!initialFileBucket?.sent_to_archive}
                      classNameCheck={styles.checkBoxSentToArchive}
                    />
                  )}

                  {isViewDoReceivedDocumentButton({
                    pk,
                    isSentToArchive: initialFileBucket.sent_to_archive,
                    isReceivedToArchive: initialFileBucket.received_to_archive,
                  }) &&
                    pk && (
                      <Button
                        className="d-block text-right mt-4"
                        type="submit"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={async () => {
                          await doArchivedDocument({
                            received_to_archive: true,
                            pk,
                          });
                        }}
                      >
                        {t('Принять в архив')}
                      </Button>
                    )}
                  {isNew && (
                    <FormGroup className="d-block text-right mt-4">
                      <Button
                        type="submit"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        {t('Добавить')}
                      </Button>
                    </FormGroup>
                  )}
                </div>
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default FileBucketForm;
