import ToolTipWrapper from 'components/controls/ToolTipWrapper/ToolTipWrapper';
import { ReactComponent as DownloadIcon } from 'img/icons/icon-downoload.svg';
import { FC } from 'react';
import { t } from 'services/utils/translation';
import styles from '../index.module.scss';

const DownloadButton: FC<{ onClick: () => Promise<void>; fileName: string }> = ({
  onClick,
  fileName,
}) => (
  <button onClick={onClick} title={fileName} className={styles.downloadButton}>
    <DownloadIcon id="ToolTip-downloadFile" className={styles.downloadIcon} />
    <ToolTipWrapper target="ToolTip-downloadFile" placement="right">
      {t('Скачать')}
    </ToolTipWrapper>
  </button>
);

export default DownloadButton;
