import React, { FC, MouseEventHandler, ReactNode } from 'react';
import { DropdownItem } from 'reactstrap';
import ToolTipWrapper from 'components/controls/ToolTipWrapper/ToolTipWrapper';
import styles from './index.module.scss';

export interface ActionsDropdownItemProps {
  onClick: MouseEventHandler<any>;
  label: string;
  icon: ReactNode;
  disabled?: boolean;
  hint?: string;
}

const ActionsDropdownItem: FC<ActionsDropdownItemProps> = ({
  onClick, 
  label, 
  icon, 
  disabled = false, 
  hint
}) => {
  return (
    <div id={'Tooltip-' + label}>
      <DropdownItem
        className={`${styles.item} py-2 d-flex flex-row`}
        onClick={onClick}
        disabled={disabled}
      >
        {icon} <span className="ml-2">{label}</span>
      </DropdownItem>
      {hint && (
        <ToolTipWrapper placement="left" target={'Tooltip-' + label}>
          {hint}
        </ToolTipWrapper>
      )}
    </div>
  );
};

export default React.memo(ActionsDropdownItem);