import { useCallback } from 'react';
import { getMimeType } from 'services/utils/getMimeType/getMimeType';

type PropsTypeUseFileDownloader = {
  fileData: string | Blob | null;
  fileName: string;
};
const useFileDownloader = (props: PropsTypeUseFileDownloader) => {
  const { fileData, fileName } = props;
  return useCallback(() => {
    if (!fileData) return;
    const mimeType = getMimeType(fileName);

    const blob =
      typeof fileData === 'string'
        ? new Blob([fileData], { type: mimeType })
        : fileData;

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }, [fileData, fileName]);
};

export default useFileDownloader;
