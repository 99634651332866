import { IFormValuesTask } from 'components/tasks/TaskForm/TaskForm';
import useGetDifferentTransformValues, {
  TChangesValuesForm,
} from 'hooks/useGettDifferentValues/useGettDifferentValues';
import TaskValuePreparer from '../TaskEditPage/utils/TaskValuePreparer';
import taskFormValuesLocalization from 'services/localLocalization/taskFormValuesLocalization';
import {
  saveTaskCurrentValues,
  setInitialFormTask,
  setTaskFormDifValues,
} from 'store/task/actions';
import useGetDifferentValues from 'hooks/useGetDifferentValues/useGetDifferentValues';
import { useCallback, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useDispatch, useSelector } from 'react-redux';
import { getTaskFormValuesFromStore } from 'store/task/selectors';

const useTaskProcessor = ({
  pk,
  setInitialFormValues,
}: {
  pk: string;
  setInitialFormValues: (values: IFormValuesTask) => void;
}) => {
  const [changedOnServerNotEditedByUser, setChangedOnServerNotEditedByUser] =
    useState<TChangesValuesForm[]>([]);

  const dispatch = useDispatch();

  const form = useSelector(getTaskFormValuesFromStore);
  const initial = form[pk]?.initial;
  const current = form[pk]?.current;

  const getDifferentValues = useGetDifferentTransformValues({
    localization: taskFormValuesLocalization,
  });
  const getDifferentTaskValues = useGetDifferentValues();

  const prepareTaskValues = useCallback(
    (task?: IFormValuesTask | null) =>
      task
        ? new TaskValuePreparer(task).prepareForComparison().getPrepareValues()
        : {},
    []
  );

  const isTaskEdited = useCallback(
    (task: IFormValuesTask | null) => !initial || !isEqual(initial, task),
    [initial]
  );

  const handleServerChanges = useCallback(
    (
      initialValues: IFormValuesTask,
      currentValues: IFormValuesTask,
      formValues: IFormValuesTask
    ) => {
      const { changedOnServerNotEditedByUser, editedButNotSaved } =
        getDifferentValues(initialValues, currentValues, formValues);

      setChangedOnServerNotEditedByUser(changedOnServerNotEditedByUser);

      if (editedButNotSaved.length > 0) {
        dispatch(setTaskFormDifValues(pk)(editedButNotSaved));

        const differentInitial = getDifferentTaskValues(
          initialValues,
          currentValues
        );

        if (Object.keys(differentInitial).length > 0) {
          dispatch(
            saveTaskCurrentValues(pk)({
              ...current,
              ...formValues,
              ...differentInitial,
            })
          );
        }
      }
    },
    [current, dispatch, getDifferentTaskValues, getDifferentValues, pk]
  );

  const convertTaskAndSetInitial = useCallback(
    (task: IFormValuesTask | null) => {
      if (!task) return;

      const preparedInitial = prepareTaskValues(initial);
      const preparedCurrent = prepareTaskValues(current);
      const preparedForm = prepareTaskValues(task);

      if (isTaskEdited(task) && task.taskPk && current?.taskPk) {
        handleServerChanges(preparedInitial, preparedCurrent, preparedForm);
      }

      setInitialFormValues(task);
      dispatch(setInitialFormTask(pk)(task));
    },
    [
      current,
      dispatch,
      handleServerChanges,
      initial,
      isTaskEdited,
      pk,
      prepareTaskValues,
      setInitialFormValues,
    ]
  );

  return {
    changedOnServerNotEditedByUser,
    setChangedOnServerNotEditedByUser,
    convertTaskAndSetInitial,
  };
};

export default useTaskProcessor;
