import React, { FC, memo } from 'react';
import { FormGroup } from 'reactstrap';
import { t } from 'services/utils/translation';
import Dropzone, { DropEvent, FileRejection } from 'react-dropzone';
import styles from './index.module.scss';
import classNames from 'clsx';
import ErrorWrapperField from '../ErrorWrapperField/ErrorWrapperField';

const DEFAULT_DESCRIPTION = t(
  'Перетащите сюда файлы, или нажмите для выбора из директории'
);
const closeIcon = '📁';
const openIcon = '📂';

const FILE_MAX_SIZE = 100_000_000;

// const acceptedFiles =
//   '.doc, application/pdf, .docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .xlsx, video/*, image/*, ';

type PropsType = {
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  multiple?: boolean;
  accept?: string;
  name: string;
  hasError: boolean;
  description?: string;
  disabled?: boolean;
};

const DropZoneField: FC<PropsType> = (props) => {
  const {
    onDrop,
    multiple = true,
    accept,
    name,
    hasError,
    description = DEFAULT_DESCRIPTION,
    disabled = false,
  } = props;
  return (
    <FormGroup
      className={classNames(styles['container-dropzone'], {
        'is-invalid': hasError,
      })}
    >
      <ErrorWrapperField id={name}>
        <Dropzone
          maxSize={FILE_MAX_SIZE}
          onDrop={onDrop}
          multiple={multiple}
          accept={accept}
          disabled={disabled}
        >
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragAccept,
            isDragReject,
          }) => {
            const additionalClass = isDragAccept
              ? 'accept'
              : isDragReject
              ? 'reject'
              : '';

            return (
              <div
                {...getRootProps()}
                className={classNames(
                  styles[additionalClass],
                  styles.dropzone,
                  { [styles.disabled]: disabled }
                )}
              >
                <div>
                  <input {...getInputProps()} />
                  <span>{isDragActive ? openIcon : closeIcon}</span>
                  <p className="mt-2">{description}</p>
                </div>
              </div>
            );
          }}
        </Dropzone>
      </ErrorWrapperField>
    </FormGroup>
  );
};

export default memo(DropZoneField);
