import ApiClientBase from './ApiClientBase';
import { isDev } from '../../config';
import ApiActions from './apiActions';
import { UNDERCURRENT_CHILD_MODULES } from '../constants/UNDERCURRENT_CHILD_MODULES';
import { ApiUrlBuilder } from './ApiUrlBuilder';
import IBaseGetListParams from './interfacesApi/IBaseGetListParams';
import deleteUnnecessaryParametersForGetListV2 from 'services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParametersForGetListV2';
import { ISuccessfulResponse } from './interfacesApi/IV2ResponseStatus';
import deleteUnnecessaryParameters from 'services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';
import TBaseOldPostResponse from './interfacesApi/TBaseOldPostResponse';
import { ApiHeaderBuilder } from './ApiHeaderBuilder';
import { sliceTimestamp } from '../utils/dateHelper/dateHelper';

interface IPostFileParams<Params extends FormData> {
  pk?: number | string;
  action: ApiActions.UPDATE | ApiActions.CREATE;
  params: Params;
}

const DEV_UNDERCURRENT_INSTALLATION_NAME = 'generic';

export class DocumentApiMethods extends ApiClientBase {
  private keyWrapperParameterForPagination = 'column_name_to_value' as const;
  public childModule: UNDERCURRENT_CHILD_MODULES =
    UNDERCURRENT_CHILD_MODULES.DEFAULT;
  constructor(childModule: UNDERCURRENT_CHILD_MODULES) {
    super(
      'undercurrent',
      isDev() ? DEV_UNDERCURRENT_INSTALLATION_NAME : null,
      'v2'
    );

    this.childModule = childModule;
  }

  public getFullUrl = (fileName: string) =>
    `${this.baseUrl}${ApiActions.UPLOADS}/${fileName}`;

  public uploadsAndCreateFileUrl = async (fileName: string) => {
    const blob = await this.uploadsAndCreateBlob(fileName);
    return URL.createObjectURL(blob);
  };

  public uploadsAndCreateBlob = async (fileName: string) => {
    const url = this.getFullUrl(fileName);
    return await this.getBlob(url);
  };

  public uploadsAndCreateFiles = async (
    fileNames: string[]
  ): Promise<File[]> => {
    const headers = new ApiHeaderBuilder().token(this.hasAccessToken).build();

    return await Promise.all(
      fileNames.map(async (fileName: string) => {
        const url = this.getFullUrl(fileName);

        const blob = await this.getBlob(url, { headers });
        return new File([blob], sliceTimestamp(fileName));
      })
    );
  };

  public create = async <FormValues extends {}, Payload>(
    formValues: FormValues
  ): Promise<ISuccessfulResponse<Payload>> => {
    const clearParams = deleteUnnecessaryParameters(formValues);

    const apiUrl = new ApiUrlBuilder()
      .childModule(this.childModule)
      .action(ApiActions.CREATE)
      .buildString();

    return this.postJson(apiUrl, clearParams);
  };

  public update = async <FormValues extends { pk?: number }, Payload>(
    formValues: FormValues
  ): Promise<ISuccessfulResponse<Payload>> => {
    const pk = formValues?.pk;
    if (!pk) {
      throw new Error('missing pk');
    }
    const apiUrl = new ApiUrlBuilder()
      .childModule(this.childModule)
      .action(ApiActions.UPDATE)
      .pk(pk)
      .buildString();

    return this.putJson(apiUrl, formValues);
  };

  public getPaginated = async <Filters extends IBaseGetListParams, Payload>(
    params: Filters
  ): Promise<Payload> => {
    const { skip, length, sort_by } = params;

    const apiUrl = new ApiUrlBuilder()
      .childModule(this.childModule)
      .action(ApiActions.PAGINATED_OLD)
      .skip(skip)
      .length(length)
      .buildString();

    const clearParams = deleteUnnecessaryParametersForGetListV2({
      ...params,
      sort_by,
    });
    const newParams = { [this.keyWrapperParameterForPagination]: clearParams };
    return this.postJson(apiUrl, newParams);
  };

  public delete = async (pk: number) => {
    const apiUrl = new ApiUrlBuilder()
      .childModule(this.childModule)
      .action(ApiActions.DELETE)
      .pk(pk)
      .buildString();
    return this.httpDelete(apiUrl);
  };

  public read = async (pk: number) => {
    const apiUrl = new ApiUrlBuilder()
      .childModule(this.childModule)
      .action(ApiActions.READ)
      .pk(pk)
      .buildString();
    return this.get(apiUrl);
  };

  public postFile = async <Payload, Params extends FormData>({
    pk,
    action,
    params,
  }: IPostFileParams<Params>): Promise<TBaseOldPostResponse<Payload>> => {
    const apiUrl = new ApiUrlBuilder()
      .childModule(this.childModule)
      .actionList([action, ApiActions.WITH_FILES])
      .pk(pk)
      .buildString();
    return this.postWithFileUpload(apiUrl, params);
  };
}

export default DocumentApiMethods;
