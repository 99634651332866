import useFileDownloader from 'hooks/useFileDownloader/useFileDownloader';
import { sliceTimestamp } from 'services/utils/dateHelper/dateHelper';
import { getMimeType } from 'services/utils/getMimeType/getMimeType';
 
const isFilePickerSupported = 'showSaveFilePicker' in window;
const useModernDownload = (blob: Blob | null, fileName: string) => {
  const downloadFile = useFileDownloader({
    fileData: blob,
    fileName: sliceTimestamp(fileName),
  });   
  return async () => {
    if (!blob) {
      console.error('Blob is not available');
      return;
    }

    if (isFilePickerSupported) {
      try {
        const mimeType = getMimeType(fileName);
        const handle = await window.showSaveFilePicker({
          suggestedName: fileName,
          types: [
            {
              description: 'Files',  
              accept: { [mimeType]: [`.${fileName.split('.').pop()}`] },
            },
          ],
        });

        const writable = await handle.createWritable();
        await writable.write(blob);
        await writable.close();
      } catch (err) {
        console.error('Download canceled:', err);
      }
    } else {
      // Fallback для браузеров без поддержки showSaveFilePicker
      downloadFile();
    }
  };
};

export default useModernDownload;
