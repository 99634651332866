import classNames from 'clsx';
import config from 'config.json';
import formatDate from 'date-fns/format';
import parse from 'date-fns/parse';
import { ReactComponent as CalendarIcon } from 'img/icons/icon-calendar.svg';
import React, {
  FC,
  FocusEventHandler,
  useRef,
  memo,
  useState,
  useCallback,
  SyntheticEvent,
} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from 'reactstrap';
import styles from './index.module.scss';
import ErrorWrapperField from '../ErrorWrapperField/ErrorWrapperField';
import { isValid } from 'date-fns';

const toInitialDate = (format: string, value?: string) => {
  if (value) {
    const parsedDate = parse(value, format, new Date());
    return isValid(parsedDate) ? parsedDate : null;
  }
  return null;
};

type TypeProps = {
  id: string;
  label: string;
  placeholder?: string;
  onBlur: FocusEventHandler;
  onChange: Function;
  hasError: boolean;
  required?: boolean;
  defaultValue?: string;
  format?: string;
  isTime?: boolean;
  minDate?: Date;
  disabled?: boolean;
  highlightEditing?: boolean;
};

const DatePickerField: FC<TypeProps> = (props) => {
  const {
    id,
    label,
    placeholder,
    onBlur,
    onChange,
    hasError,
    required = false,
    defaultValue,
    minDate,
    disabled,
    highlightEditing = false,
    format = config.clientDateFormat,
    isTime,
  } = props;

  const inputRef = useRef<DatePicker>(null);
  const [inputValue, setInputValue] = useState(defaultValue || '');

  const handleIconClick = () => {
    inputRef.current?.setOpen(true);
  };
  const initialValue = toInitialDate(format, defaultValue);

  const handleChange = (
    date: Date | null,
    event: SyntheticEvent<any> | undefined
  ) => {
    if (date) {
      const formatted = formatDate(date, format);
      onChange(formatted);
      setInputValue(formatted);
    } else {
      onChange('');
      setInputValue('');
    }
  };

  const handleRawChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);

    // Парсим введенное значение
    const parsedDate = parse(value, format, new Date());

    if (isValid(parsedDate)) {
      onChange(formatDate(parsedDate, format));
    } else {
      onChange(''); // Сбрасываем если невалидно
    }
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    if (!isValid(parse(inputValue, format, new Date()))) {
      onChange('');
      setInputValue('');
    }
    onBlur(e);
  };

  return (
    <FormGroup className={classNames({ required: required })}>
      <Label className="control-label d-block" for={id}>
        {label}
      </Label>
      <ErrorWrapperField id={id}>
        <InputGroup
          className={classNames('flex-nowrap', {
            'is-invalid': hasError,
            borderBackLight: highlightEditing && !hasError,
            [styles['is-invalid']]: hasError,
          })}
        >
            <InputGroupAddon addonType="prepend" onClick={handleIconClick}>
            <InputGroupText className={styles.calendarIconContainer}>
              <CalendarIcon className={styles.calendarIcon} />
            </InputGroupText>
          </InputGroupAddon>
          <DatePicker
            wrapperClassName={styles.wrapperClassName}
            ref={inputRef}
            name={id}
            locale="ru"
            placeholderText={placeholder ? placeholder : label}
            selected={initialValue}
            dateFormat={format}
            onChangeRaw={handleRawChange}
            onBlur={handleBlur}
            onChange={handleChange}
            className={classNames('form-control', styles.field, {
              [styles['is-invalid']]: hasError,
            })}
            required={required}
            showTimeSelect={isTime}
            minDate={minDate}
            disabled={disabled}
            calendarStartDay={1}
          />
        </InputGroup>
      </ErrorWrapperField>
    </FormGroup>
  );
};

export default memo(DatePickerField);
